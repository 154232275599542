import { useEffect } from 'react'

import { useRouter } from 'next/router'

import { HOME } from '~/constants/routes'

const IndexPage = () => {
  const router = useRouter()

  useEffect(() => {
    void router.push(HOME)
  })

  return null
}

export default IndexPage
